<template>
    <div>
 <vx-card id="invoice-container" class="bg-h-primary-light">

            <!-- INVOICE METADATA -->
            <div class="vx-row ">
                <div class="vx-col w-full flex justify-between">
                  <div>

                    <h1>تراکنش های درگاه</h1>
                  </div>
                    <div class="invoice__invoice-detail bg-h-primary p-3 rounded">
                        <h6 class="">تاریخ امروز</h6>
                        <p>{{ todayDate }}</p>
                    </div>
                </div>

            </div>
            <!-- INVOICE CONTENT -->
            <div class="p-base text-white">
                <!-- INVOICE TASKS TABLE -->

                <b-table :stacked="windowWidth < 800" striped hover :fields="fields" :items="onlinePayment" responsive="sm" class="text-white">
                        <!-- A virtual column -->
                        <template #cell(payment_status)="data" >
                             <vs-chip color="success"  v-if="data.value == 1"> پرداخت شده</vs-chip>
                            <vs-chip color="danger"  v-if="data.value == 2">پرداخت نشده</vs-chip>
                        </template>
                        </b-table>
                             <vs-pagination :total="countPage" v-model="pageNumber"></vs-pagination>


            </div>
            <div class="invoice__footer text-right p-base text-white">


            </div>
        </vx-card>
    </div>
</template>
<script>
export default {
  name : 'PaymentPage',
  data () {
    return {
      onlinePayment : [],
      pageNumber    : 1,
      countPage     : 1,
      fields: [

        { key: 'index', label: 'ردیف' },
        { key: 'created_at', label: 'تاریخ' },
        { key: 'amount', label: 'مبلغ' },
        { key: 'payment_status', label: 'وضعیت پرداخت' }
      ]
    }
  },
  watch : {
    pageNumber () {
      this.paymentList()
    }
  },
  methods : {
    paymentList () {
      this.$http.get(`/billing/pay/list?type=1&pay_type=1&page=${this.pageNumber}`).then((res) => {
        this.onlinePayment = res.data.params.data

        this.countPage = res.data.params.last_page
      }).catch(() => {

      })
    }
  },
  computed : {
    todayDate () {
      const locale = 'fa-ir'
      const date_obj = new Date(Date.now())
      const monthName = date_obj.toLocaleString(locale)
      return monthName
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  created () {
    this.paymentList()
  }
}
</script>

